import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout'
import microfrontendLayout from './microfrontend-layout.html'
import { startMonitoring } from './services/monitoring'

if (process.env.USE_MSW_MOCK === 'true') {
  import('./mocks/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    })
  })
}

const routes = constructRoutes(microfrontendLayout)

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  },
})

const layoutEngine = constructLayoutEngine({ routes, applications })

applications.forEach(registerApplication)

layoutEngine.activate()

startMonitoring()

start()
